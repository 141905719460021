<template>
    <div>
      <v-toolbar flat>
        <v-icon left>mdi-account-box-outline</v-icon>
        <v-toolbar-title>PLANTILLAS DE MENSAJE</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="abrirDialogPlantilla(null)" color="blue" small dark
          ><v-icon>mdi-plus</v-icon> Crear</v-btn
        >
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items-per-page="itemsPerPage"
        :items="plantillas"
        class="elevation-1"
        small
        item-key="message_template_id"
        hide-default-footer
      >
        <template v-slot:item.opciones="{ item }">
          <v-btn
            small
            text
            @click="borrarPlantilla(item.message_template_id)"
            color="blue"
            small
            dark
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
          >
          <v-btn icon small color="green" @click="editarPlantilla(item)">
            <v-icon small color green>mdi-pencil</v-icon>
          </v-btn>
        </template>
  
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
  
      <!-- DIALOGO PLANTILLA -->
      <v-dialog v-model="dialogoPlantilla" persistent width="700">
        <v-form ref="formPlantilla">
          <v-card>
            <v-toolbar dark color="blue" height="40" flat>
              <v-icon left>mdi-account-box-outline</v-icon>
              Plantilla
              <v-spacer></v-spacer>
              <v-btn small text @click="dialogoPlantilla = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="12" class="py-0 my-0">
                <v-text-field
                  :rules="requiredRule"
                  v-model="datosPlantilla.nombre"
                  label="Nombre"
                ></v-text-field>
              </v-col>
  
              <v-col md="12" class="py-0 my-0">
                <v-textarea
                  :rules="requiredRule"
                  v-model="datosPlantilla.texto"
                  label="Texto"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="6" class="py-0 my-0">
                <v-select                  
                  v-model="datosPlantilla.modulo"
                  label="Módulo"
                  :items="modulos"
                  item-text="nombre"
                  item-value="codigo"
                ></v-select>
              </v-col>
            </v-row> -->

            <v-row class="mt-1 mx-2 pt-0 mt-0">
              <v-col md="6" class="py-0 my-0">
                <v-text-field
                  type="date"
                  v-model="datosPlantilla.desde"
                  label="Desde"
                ></v-text-field>
              </v-col>
  
              <v-col md="6" class="py-0 my-0">
                <v-text-field
                  type="date"
                  v-model="datosPlantilla.hasta"
                  label="Hasta"
                ></v-text-field>
              </v-col>
            </v-row>
  
            <v-toolbar class="pt-2 pb-2" flat>
              <v-btn class="mr-4" color="primary" small @click="guardarPlantilla">
                <v-icon>mdi-content-save</v-icon>Guardar
              </v-btn>
  
              <v-btn class="mx-4" text small @click="dialogoPlantilla = false">
                <v-icon>mdi-content-cancel</v-icon>Cancelar
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import Vue from "vue";
  
  export default {
    name: "FuentesLeads",
    data: () => ({
      requiredRule: [(v) => !!v || "El campo es requerido"],
      options: {},
      setfilters: [],
      selected: [],
      users: [],
      totalItems: 1,
      currentPage: 1,
      itemsPerPage: 10,
      pageCount: 0,
      required: [(v) => !!v || "El campo es requerido"],
      headers: [
        { text: "Opciones", align: "start", value: "opciones", width: 120 },
        { text: "ID", align: "start", value: "message_template_id" },
        { text: "Nombre", align: "start", value: "nombre" },
        { text: "Texto", align: "start", value: "texto" },
        { text: "Desde", align: "start", value: "desde" },
      ],
  
      dialogoPlantilla: false,
      datosPlantilla: {
        message_template_id: "",
        nombre: "",
        texto: "",
        desde: "",
        hasta: "",
      },
      crearPlantilla: false,
      plantillas: [],
      modulos: [
        {codigo:"Contacto", nombre:"Contacto"}, 
        {codigo:"Cotizacion",nombre:"Cotizacion"},
        {codigo:"Oportunidad", nombre:"Oportunidad"},
        {codigo:"Invoice", nombre:"Invoice"},
        {codigo:"Evento", nombre:"Evento"}
      ],
      campos: [
        {
          modulo: "Evento",
          campos: ["work_effort_id","work_effort_type_id", "current_status_id",
        "work_effort_name",  "servicio_crm_id", "created_date" , "created_by_user_login",
         "estimated_start_date", "fecha_hora_completa" , "HORA_CITA"]
        }
      ]

    }),
    computed: {
      ...mapState("master", ["loadingBtn", "companies"]),
      ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
  
      ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
      ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
      ]),
  
      ...mapActions("master", ["requestApi", "alertNotification"]),
      ...mapActions("access", []),
  
      cargarDatos() {
        this.setLoadingTable(true);
  
        this.setUrl("message-template");
  
        this.requestApi({
          method: "GET",
          data: {
            page: this.currentPage,
            page_size: this.itemsPerPage,
            paginator: true,
          },
        })
          .then((res) => {
            //console.log(res.data);
            this.plantillas = res.data._embedded.message_template;
            this.pageCount = res.data.page_count;
            this.currentPage = res.data.page;
            this.totalItems = res.data.total_items;
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
      abrirDialogPlantilla(plantillaId) {
        this.dialogoPlantilla = true;
        this.datosPlantilla = {
          message_template_id: "",
          nombre: "",
          texto: "",
          desde: "",
          hasta: "",
        };
        this.crearPlantilla = true;
        if (plantillaId != null) {
          this.crearPlantilla = false;
          this.cargarDatos(plantillaId);
        }
      },
  
      editarPlantilla(item) {
        this.dialogoPlantilla = true;
        this.crearPlantilla = false;
        //console.log(item)
  
        this.datosPlantilla = {
          message_template_id: item.message_template_id,
          nombre: item.nombre,
          texto: item.texto,
          desde: item.desde.substring(0,10),
          hasta: item.hasta != null ? item.hasta.substring(0,10) : '',
        };

        this.crearPlantilla = false;
      },
  
      guardarPlantilla() {
        if (!this.$refs.formPlantilla.validate()) {
          return false;
        }
        this.setLoadingTable(true);
        this.setUrl("message-template");
        this.requestApi({
          method: "POST",
          data: {
            accion:
              this.crearPlantilla == true ? "crearTemplate" : "modificarTemplate",
            plantilla: this.datosPlantilla,
          },
        })
          .then((res) => {
            //console.log(res.data);
            this.cargarDatos();
            this.dialogoPlantilla = false;
          })
          .then(() => {
            this.setLoadingTable(false);
          });
      },
      borrarPlantilla(plantillaId) {
        Vue.swal({
          html: "Está seguro de eliminar esta plantilla ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cerrar",
          ...this.paramAlertQuestion,
        }).then((result) => {
          if (result.isConfirmed) {
            this.setLoadingTable(true);
            this.setUrl("message-template");
            this.requestApi({
              method: "DELETE",
              data: {
                accion: "borrarPlantilla",
                message_template_id: plantillaId,
              },
            })
              .then((res) => {
                this.cargarDatos();
                this.dialogoPlantilla = false;
              })
              .then(() => {
                this.setLoadingTable(false);
              });
          }
        });
      },
      handlePageChange(value) {
        this.cargarDatos();
      },
    },
    mounted() {
      this.cargarDatos();
      this.setTitleToolbar("PLANTILLA");
    },
  };
  </script>
  